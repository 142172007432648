<template>
  <div class="row">
    <modal
        name="open_to_send_form"
        :adaptive="true"
        height="auto"
        width="500"
    >
      <esc-send-form
          emails="escEmailWillSend"
          languages="escEmailWillSendLanguages"
          @onSubmit="escSendEmailIsSubmit"
      >
      </esc-send-form>
    </modal>
    <div class="col-md-12">
      <div id="filter-card" class="d-flex justify-content-between items-baseline w-100 mb-2"
           style="margin-bottom: -5px;">
        <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
          <i class="fa fa-chevron-left"></i>
        </span>
        <h3 id="page-title" class="d-inline-block">{{ $t('esc.esc_table') }}</h3>
        <div class="flex-grow-1 px-3 d-flex flex-row-reverse">
          <div class="d-inline-block" v-for="(i, index) in inputForm.length" :key="i">
            <button-with-icon
                @onClick="changeTable(index)"
                icon-name=""
                class="mr-3"
                :class="{'bg-primary text-white': index === currentSelectedTable, 'text-primary': index !== currentSelectedTable}"
                size="md"
                :text="`${i}. ESC`"
            ></button-with-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <dashboard-box class="bg-white" id="mainDiv" v-if="authUser">
        <template slot="preview">
          <div class="p-2 d-flex">
            <div class="w-125px h-125px  p-3">
              <img :src="form.image_link === null ? getIconByKey('general.no-image') : form.image_link" @click="getOriginalImage"
                   id="image_holder"
                   class="w-75px h-75px d-inline-block object-cover cursor-pointer" style="border-radius: 10px">
              <input type="file" ref="file" style="display: none" @change="imageChange"
                     class="input-file form-control">
              <div class="w-20px h-20px ml-8" @click="openUploadDocumentModal">
                      <span  v-html="getIconByKey('icons.waybill.waybill_entry', {
                       class: 'w-30px h-30px d-inline-block object-fill cursor-pointer'
                      })">
                      </span>
              </div>
            </div>
            <div class="flex-grow-1 row p-3">
              <div class="col-12 row">
                <div class="col-3 text-lg">
                  <span class="font-weight-boldest">{{ $t('esc.new_date') }} :</span> {{ form.created_at | laravelToMomentTimeFormat }} <br>
                  <span class="font-weight-boldest">{{ $t('esc.updated_date') }} :</span> {{form.updated_at | laravelToMomentTimeFormat }}<br>
                  <span v-if="form.duplicated"><span class="font-weight-boldest">{{ $t('esc.duplicated_esc') }} :</span> {{form.duplicated.esc_number}}</span>
                </div>
                <div class="col-3 text-lg">
                  <span class="font-weight-boldest">{{ $t('esc.esc_new_esc_no') }} :</span>
                  {{ form.esc_number || '-' }}
                </div>

                <div class="col-3 text-lg">
                  <span class="font-weight-boldest">{{ $t('esc.order_dependent') }} :</span>
                  {{ form.order_number }}
                </div>
                <div class="col-3 text-lg">
                  <span class="font-weight-boldest">{{ $t('esc.new_make_it') }} :</span> {{ inputForm[this.currentSelectedTable]&&inputForm[this.currentSelectedTable].user?inputForm[this.currentSelectedTable].user.name:authUser.name }}
                </div>
              </div>
              <div class="col-12 row">
                <div class="col-4">
                  <custom-multi-select
                      class="font-weight-bolder"
                      :title="$t('esc.new_customer')"
                      :helperText="$t('esc.new_please_enter')"
                      :required="true"
                      :options="companyOptions"
                      :model.sync="form.company_id"
                      :max="1"
                      name="company_id"
                      :not-list="true"
                      :is-inline="false"
                  >
                  </custom-multi-select>
                </div>
                <div class="col-4">
                  <text-input
                      class="font-weight-bolder"
                      :title="$t('esc.new_model')"
                      :helper-text="$t('esc.new_please_enter')"
                      name="user_model"
                      :model.sync="form.model"
                      label-class="font-weight-bolder text-xl"
                      :is-inline="false"
                  ></text-input>
                </div>
                <div class="col-4">
                  <text-input
                      class="font-weight-bolder"
                      :title="$t('esc.new_description')"
                      :helper-text="$t('esc.new_please_enter')"
                      name="user_description"
                      label-class="font-weight-bolder text-xl"
                      :model.sync="form.description"
                      :is-inline="false"
                  ></text-input>
                </div>
              </div>
              <div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="col-12 p-0 m-0 text-primary">
              <h4 class="d-block text-center">
                {{ $t('esc.new_cloth_price') }}
                <span class="float-right" @click="addNowRowTable" v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-35px h-35px d-inline-block object-fill cursor-pointer'
                })">
             </span>
              </h4>
            </div>
            <div class="w-100 border-15px navy-blue-border-1px row mx-0">
              <div class="col-12 d-flex navy-blue-border-bottom-1px">
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  {{ $t('esc.new_cloth') }}
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  {{ $t('esc.new_en') }}
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  {{ $t('esc.new_gr') }}
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  {{ $t('esc.unit') }}
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  {{ $t('esc.new_s_tr') }}
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  {{ $t('esc.new_tr') }}
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg py-2">
                  {{ $t('esc.new_price') }}
                </div>
              </div>
              <div class="col-12 d-flex"
                   v-for="(fabric_calculation, index) in form.fabric_calculations">
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  <input
                      type="text"
                      class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                      :placeholder="$t('esc.new_hand_enter')"
                      v-model="fabric_calculation.fabric"
                  >
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  <div class="w-100">
                    <input
                        type="text"
                        class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                        :placeholder="$t('esc.new_hand_enter')"
                        v-model="fabric_calculation.width"
                    >
                  </div>
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  <input
                      type="text"
                      class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                      :placeholder="$t('esc.new_hand_enter')"
                      v-model="fabric_calculation.weight"
                  >
                </div>

                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  <custom-multi-select
                      :options="unitOptions"
                      :model.sync="fabric_calculation.unit_id"
                      :placeholder="$t('order.please_enter_manually')"
                      :is-inline="false"
                      :title="null"
                      :required="false"
                      :name="`order_fabric_unit_id_${index}`"
                      :input-width="`65px`"
                      :item-per-row="1"
                      :not-list="true"
                      :max="1"
                  ></custom-multi-select>
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  <input
                      type="text"
                      class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                      :placeholder="$t('esc.new_hand_enter')"
                      v-model="fabric_calculation.consumption"
                  >
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  <input
                      type="text"
                      class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                      :placeholder="$t('esc.new_hand_enter')"
                      v-model="fabric_calculation.price"
                  >
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg py-2">
                  <div class="w-100">
                    <span class="d-block text-sm text-center w-100 ">{{ fabric_calculation.total }}</span>
                    <span class="float-right bg-red-500" v-if="isDeleteCloth(index)" @click="deleteRowTable(index)" v-html="getIconByKey('icons.sample.delete_red', {
                  class: 'w-25px h-25px d-inline-block bg-red-500 object-fill cursor-pointer'
                })">
             </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex p-0 pb-0">
            <div class="col flex-grow-1 p-5">
              <div class="col-12 row justify-content-center p-0 m-0 text-primary">
                <h4> {{ $t('esc.new_total_price') }}</h4>
              </div>
              <div class="flex-grow-1 row">
                <div class="col-12 m-0 py-1 text-xl border border-right-0 firstTable1">
                  <h4 class="d-block text-center text-black mt-1 mb-0">
                    {{ $t('esc.new_general_price') }}
                    <div class="float-right">
                      <CustomMultiSelect
                          :title="null"
                          class="w-100"
                          :required="true"
                          :options="expensesOptions"
                          :model.sync="form.selectedExpenses"
                          :name="$t('esc.new_please_select')"
                          object-key="translations.0.name"
                          :has-bottom-margin="false"
                          width="200px"
                          :isRight="true"
                      >
                        <template slot="inputDesign">
                          <div class="w-100 d-flex justify-content-center">
                          <span class="items-center" v-html="getIconByKey('icons.waybill.waybill_entry', {
                              class: 'w-35px h-35px  object-fill flex-grow-1 cursor-pointer'
                            })">
                          </span>
                          </div>
                        </template>
                      </CustomMultiSelect>
                    </div>
                  </h4>
                </div>
              </div>

              <div class="flex-grow-1 row">
                <div class="row col-12 px-0 border border-top-0 border-bottom-0 m-0">
                  <div class="row flex-1 px-0 mx-0 border border-left-0 justify-content-center items-center pt-2"
                       :class="{'border-right-0': form.optionalExpenses.length === index + 1}"
                       v-for="(expense, index) in form.optionalExpenses" :key="index">
                    <text-input
                        class="font-weight-bolder"
                        :title="expense.name" :name="expense.name"
                        :step="1"
                        :model.sync="expense.amount"
                        :helper-text="$t('esc.new_table_input')"
                        input-max-width="auto"
                        input-width="95px"
                        :is-inline="false"
                    ></text-input>
                  </div>
                </div>
                <div class="col-12 px-0 flex items-center m-0 border justify-content-start items-center">
                  <div class="col-lg-12 px-0 col-md-12 col-sm-12 row m-0 mb-3">
                    <template>
                      <div class="col-2 px-0 d-flex justify-content-center border border-left-0 items-center pt-2"
                           v-for="(value, index) in form.selectedExpenses">
                        <text-input
                            class="font-weight-bolder mb-0"
                            :title="getValueByDottedKey(expensesOptions[value], 'translations.0.name')"
                            name="weight"
                            :model.sync="form.expenses[index].amount"
                            :placeholder="$t('esc.new_table_input')"
                            :is-inline="false"
                            input-width="100px"
                            input-max-width="auto"
                        ></text-input>
                        <span class="items-center red" v-if="!id" @click="deleteExpensesOptions(index)" v-html="getIconByKey('icons.sample.delete_red', {
                              class: 'w-25px h-25px  red object-fill flex-grow-1 cursor-pointer'
                            })">
                          </span>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="flex-grow-1 row">
                <div
                    class="col-6 row justify-content-start items-center m-0 p-0 border border-top-0 border-right-0 pt-3">
                </div>
                <div
                    class="col-6 row d-flex items-center justify-content-end m-0 pl-16 text-primary border border-left-0 border-top-0 border-left-0 pt-3">
                  <h4>
                    {{ $t('esc.expense_total') }} : {{ totalAmount }}
                    <span class="w-10" v-html="getIconByKey('icons.sample.enter-product-name', {
                    class: 'w-20px h-20px d-inline-block object-fill cursor-pointer'
                    })">
                    </span>
                  </h4>
                </div>
              </div>
              <div class="flex-grow-1 row">
                <div
                    class="col-6 row justify-content-start items-center m-0 p-0 border border-top-0 border-right-0 pt-3">
                </div>
                <div
                    class="col-6 row d-flex items-center justify-content-end m-0 pl-16 text-primary border border-left-0 border-top-0 border-left-0 pt-3">
                  <h4>
                    {{ $t('esc.total') }} : {{ fabricAndExpenseTotal }}
                    <span class="w-10" v-html="getIconByKey('icons.sample.enter-product-name', {
                    class: 'w-20px h-20px d-inline-block object-fill cursor-pointer'
                    })">
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row w-100 px-3 mx-0">
            <div class="border py-2 px-3 d-flex flex-column justify-content-center">
              <span class="text-lg font-weight-bolder">{{ $t('esc.wastage') }} :
                <span class="text-right d-inline-block">
                  %{{ (form.wastage || escConfigurations.wastage) }}</span>
              </span>
            </div>
            <div class="border py-2 px-3 d-flex flex-column justify-content-center">
              <span class="text-lg font-weight-bolder">{{ $t('esc.exchange_difference') }} : <span
                  class="text-right d-inline-block">%{{
                  Number(form.exchange_difference || escConfigurations.exchange_difference)
                }}</span>
              </span>
            </div>
            <div class="flex-1 border py-2 px-3">
              <number-input
                  class="font-weight-bolder"
                  name="exchange_difference"
                  :model.sync="form.dividend"
                  :title="$t('esc.dividend') + '(%)'"
                  :min="escConfigurations.dividend"
                  :step="1"
                  input-max-width="125px"
                  :required="true"
                  :has-bottom-margin="false"
                  :is-inline="false"
                  :max-error-message="$t('esc.please_enter_valida_value_for_dividend', {value: escConfigurations.dividend})"
              ></number-input>
            </div>
            <div class="flex-1 border py-2 px-3">
              <custom-multi-select
                  @save="onCurrencyChange"
                  class="font-weight-bolder "
                  :options="currencyUnitOptions"
                  :model.sync="form.preview_currency_unit_code"
                  :title="$t('esc.preview_currency')"
                  :placeholder="$t('esc.please_select_currency')"
                  object-key="code"
                  name="currency_unit"
                  :max="1"
                  :min="1"
                  :not-list="true"
                  :has-bottom-margin="false"
                  :is-inline="false"
                  input-width="125px"
              ></custom-multi-select>
            </div>
            <div class="flex-1 border py-2 px-3">
              <custom-multi-select
                  class="font-weight-bolder"
                  :options="currencyUnitOptions"
                  :model.sync="form.currency_unit_code"
                  :title="$t('esc.currency')"
                  :placeholder="$t('esc.please_select_currency')"
                  object-key="code"
                  name="currency_unit"
                  :max="1"
                  :min="1"
                  :not-list="true"
                  :has-bottom-margin="false"
                  :is-inline="false"
                  input-width="125px"
              ></custom-multi-select>
            </div>
            <div class="flex-1 border py-2 px-3">
              <number-input
                  class="font-weight-bolder"
                  name="exchange_rate"
                  :model.sync="form.exchange_rate"
                  :title="$t('esc.exchange_rate')"
                  :step="1"
                  input-max-width="125px"
                  :required="true"
                  :has-bottom-margin="false"
                  :is-inline="false"
              ></number-input>
            </div>
          </div>
          <div class="row w-100 mt-5">
            <div class="col-12 d-flex justify-content-center">
              <span class="text-4xl font-weight-bolder">{{ $t('esc.general_total').toUpperCase() }} :
                {{ generalTotalAmount }} {{ form.currency_unit_code }}
              </span>
            </div>
          </div>
          <div class="d-flex pb-0">
            <div class="col flex-grow-1 p-3">
              <div v-if="!form.is_submitted" class="flex-grow-1 justify-content-center row">
                <div class="d-flex justify-content-center mt-3">
                  <button-with-icon
                      v-if="$route.query.redirectName == null"
                      @onClick="submitESC"
                      :icon-name="null"
                      class="text-primary mr-3"
                      :disabled="!isFormValid || isSubmitting"
                      size="lg"
                      :text="$t('esc.new_save')"
                  ></button-with-icon>
                  <div
                      v-if="$route.query.redirectName === 'order' && !isSubmitting"
                      @click="submitESC"
                      class="w-85px h-85px rounded-full primary-border-2px text--primary d-flex flex-column justify-content-center items-center cursor-pointer">
                    <span v-html="getIconByKey('icons.order.connect_to_order', {
                      class: 'w-35px h-35px d-inline-block object-cover'
                    })"></span>
                    <span class="text-center" v-html="$t('order.connect_to_order')"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="form.is_submitted">
            <div class="d-flex justify-content-center">
              <button-with-icon
                  @onClick="openInNewTab(form.esc_pdf_link)"
                  :icon-name="null"
                  class="text-primary mr-3"
                  size="lg"
                  :text="$t('esc.new_pdf').toUpperCase()"
              ></button-with-icon>
              <button-with-icon
                  @onClick="openInNewTab(form.esc_company_pdf_link)"
                  :icon-name="null"
                  class="text-primary mr-3"
                  size="lg"
                  :text="$t('esc.new_company_pdf').toUpperCase()"
              ></button-with-icon>
              <button-with-icon
                  @onClick="openInNewTab(form.esc_excel_link)"
                  :icon-name="null"
                  class="text-primary mr-3"
                  size="lg"
                  :text="$t('esc.new_excel').toUpperCase()"
              ></button-with-icon>
              <button-with-icon
                  @onClick="createNewTable"
                  icon-name=""
                  class="text-primary mr-3"
                  size="lg"
                  :text="$t('esc.new_change').toUpperCase()"
              ></button-with-icon>
              <button-with-icon
                  @onClick="duplicateTable"
                  icon-name=""
                  class="text-primary mr-3"
                  size="lg"
                  :text="$t('esc.duplicate').toUpperCase()"
              ></button-with-icon>
              <button-with-icon
                  @onClick="updateTable"
                  v-if="form.order_number==='-'"
                  icon-name=""
                  class="text-primary mr-3"
                  size="lg"
                  :text="$t('esc.update').toUpperCase()"
              ></button-with-icon>
              <button-with-icon
                  @onClick="openToSend"
                  icon-name=""
                  class="text-primary"
                  size="lg"
                  :text="$t('esc.new_send').toUpperCase()"
              ></button-with-icon>
            </div>
          </template>
        </template>
      </dashboard-box>
    </div>

    <modal :adaptive="true" height="auto" name="upload-new-image" width="750">
      <div class="card card-custom">
        <div class="card-body card-body-scroll">
          <photo-recorder
              :model.sync="form.image"></photo-recorder>
          <file-input
              ref="imageFileInput"
              :model.sync="form.image"
              :title="$t('editSample.image')"
              accept="image/*"
              name="content-image">
            <template v-slot:inputDesign>
              <div class="w-100 d-flex justify-content-center" @click="$refs.imageFileInput.openFileInput()">
                <span v-html="getIconByKey('icons.meeting.select-from-file', {
                      class: 'w-75px h-75px d-inline-block object-fill cursor-pointer'
                })">
                </span>
              </div>
            </template>
          </file-input>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <button class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3 btn-pill"
                  type="button"
                  @click="cancelDocumentUpload">
            {{ $t('editSample.close') }}
          </button>
          <button class="btn btn-sm btn-primary font-weight-bold cursor-pointer btn-pill"
                  type="button"
                  @click="closeUploadDocumentModal">{{ $t('editSample.save') }}
          </button>
        </div>
      </div>
    </modal>

    <CoolLightBox
        :items="imageLinks"
        :index="imageIndex"
        @close="imageIndex = null">
    </CoolLightBox>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapMutations, mapGetters} from 'vuex'
import $ from 'jquery';
import {
  CREATE_ITEM,
  UPDATE_ITEM_BY_ID,
  ERROR,
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS as REST_GET_ITEM,
  GET_ITEMS,
  LOADING
} from "@/core/services/store/REST.module";
import NumberInput from "@/assets/components/inputs/NumberInput";
import InputFilter from "@/assets/components/filters/InputFilter";
import TextInput from "@/assets/components/inputs/TextInput";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import {SET_CURRENT_ITEM} from "@/core/services/store/panel-managment/crud/panel.management.module";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import moment from "moment";
import {LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import EscSendForm from "@/view/pages/esc/layout/edit/EscSendForm";
import SampleIndexNavPanel from "@/view/pages/samples/layout/SampleIndexNavPanel";
import DashboardBox from "@/assets/components/DashboardBox";
import FileInput from "@/assets/components/inputs/FileInput";
import PhotoRecorder from "@/assets/components/recorder/PhotoRecorder";

export default {
  name: "NewEscEntry",
  components: {
    SampleIndexNavPanel,
    InputFilter,
    TextInput,
    DatePickerInput,
    MultipleSelectInput,
    SelectInput,
    CustomMultiSelect,
    ButtonWithIcon,
    NumberInput,
    EscSendForm,
    DashboardBox,
    PhotoRecorder,
    FileInput
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
    }),
    totalAmount() {
      let temp = [...this.form.expenses, ...this.form.optionalExpenses], total = 0;
      for (let i = 0; i < temp.length; i++) {
        if (!isNaN(Number(temp[i].amount))) {
          total += Number(temp[i].amount)
        }
      }
      return (total).toFixed(2);
    },
    fabricAndExpenseTotal() {
      let total = 0;
      this.form.fabric_calculations.forEach(fabric => {
        total += (Number(fabric.consumption) * Number(fabric.price))
      });

      return Number(total + Number(this.totalAmount)).toFixed(2);
    },
    generalTotalAmount() {
      let total = 0;
      for (let i = 0; i < this.form.fabric_calculations.length; i++) {
        this.form.fabric_calculations[i].total = Number(this.form.fabric_calculations[i].price) * Number(this.form.fabric_calculations[i].consumption);
        total += this.form.fabric_calculations[i].total;
      }
      total = (Number(total) + Number(this.totalAmount));
      total = total * (1 + ((this.escConfigurations.wastage / 100) + (this.escConfigurations.exchange_difference / 100) + (Number(this.form.dividend) / 100)));

      return Number(total).toFixed(2);
    },
    escConfigurations() {
      let settings = {
        dividend: 0,
        exchange_difference: 0,
        wastage: 0,
      };
      if (this.generalSetting.length) {
        settings = _.first(this.generalSetting)['esc_configurations'];

        if (this.form) {
          this.form.dividend = settings.dividend;
        }
      }
      return settings;
    },
    isFormValid() {
      if (Number(this.form.company_id) === 0) return false;
      if (this.form.description === null || this.form.description === '') return false;
      if (this.form.model === null || this.form.model === '') return false;
      if (this.form.image_link === null && this.form.image === null) return false;
      if (this.form.currency_unit_code === null) return false;
      if (this.form.preview_currency_unit_code === null) return false;
      if (Number(this.form.dividend) === 0) return false;
      for (let i = 0; i < this.form.fabric_calculations.length; i++) {
        if (this.form.fabric_calculations[i].fabric === null || this.form.fabric_calculations[i].fabric === '') return false;
        if (!this.form.fabric_calculations[i].weight) return false;
        if (!this.form.fabric_calculations[i].width) return false;
        if (!this.form.fabric_calculations[i].unit_id) return false;
        if (Number(this.form.fabric_calculations[i].price) === 0) return false;
        if (Number(this.form.fabric_calculations[i].consumption) === 0) return false;
      }
      for (let i = 0; i < this.form.expenses.length; i++) {
        if (Number(this.form.expenses[i].expense_id) === 0) return false;
        if (Number(this.form.expenses[i].amount) === 0) return false;
      }
      for (let i = 0; i < this.form.optionalExpenses.length; i++) {
        if (Number(this.form.optionalExpenses[i].expense_id) === 0) return false;
        if (Number(this.form.optionalExpenses[i].amount) === 0) return false;
      }
      return true;
    },

  },
  data() {
    return {
      imageLinks: [],
      imageIndex: null,
      unitOptions: [],
      isSubmitting: false,
      optionalExpenseOptions: [],
      optionalExpenses: [],
      expensesOptions: [],
      expensesOptionsRaw: [],
      today: moment(),

      // Options
      companyOptions: [],
      currencyUnitOptions: {},
      id: this.$route.params.id,
      currentSelectedTable: 0,

      defaultForm: {
        id: null,
        user:null,
        copied_id: null,
        is_submitted: false,
        exchange_rate: 1,
        image_link: null,
        company_id: null,
        esc_pdf_link: null,
        esc_company_pdf_link: null,
        esc_excel_link: null,
        model: null,
        preview_currency_unit_code: 'TL',
        currency_unit_code: 'TL',
        amount: null,
        dividend: 0,
        in_use: false,
        fabric_calculations: [
          {
            fabric: null,
            width: null,
            weight: null,
            consumption: null,
            price: null,
            total: null,
            unit_id: null
          }
        ],
        image: null,
        selectedExpenses: [],
        esc_number: null,
        order_id: null,
        signature: null,
        date: moment().format(MOMENT_SYSTEM_DATE_FORMAT),
        expenses: [],
        optionalExpenses: [],
      },
      form: {
        id: null,
        copied_id: null,
        is_submitted: false,
        exchange_rate: 1,
        image_link: null,
        company_id: null,
        esc_pdf_link: null,
        esc_company_pdf_link: null,
        esc_excel_link: null,
        model: null,
        preview_currency_unit_code: 'TL',
        currency_unit_code: 'TL',
        amount: null,
        order_number: "-",
        dividend: 0,
        wastage: 0,
        exchange_difference: 0,
        in_use: false,
        fabric_calculations: [
          {
            fabric: null,
            width: null,
            weight: null,
            consumption: null,
            price: null,
            total: null,
            unit_id: null
          }
        ],
        image: null,
        selectedExpenses: [],
        esc_number: null,
        order_id: null,
        signature: null,
        date: moment().format(MOMENT_SYSTEM_DATE_FORMAT),
        expenses: [],
        optionalExpenses: [],
      },
      // Form
      inputForm: [],
    }
  },
  methods: {
    isDeleteCloth(index){
      if(this.form.fabric_calculations.length>1&&this.form.order_number=='-'){
        return true
      }if(index>0&&!this.id){
        return true
      }
      return false
    },
    cancelDocumentUpload() {
      this.form.image = null;
      this.closeUploadDocumentModal();
    },
    closeUploadDocumentModal() {
      if (this.form.image) {
        const img = document.getElementById(`image_holder`);
        if (img) {
          img.src = URL.createObjectURL(this.form.image)
        }
      }
      this.$modal.hide('upload-new-image');
    },
    openUploadDocumentModal() {
      this.$modal.show('upload-new-image');
    },
    getOriginalImage() {
      this.imageLinks = [this.form.image_link_large];
      this.imageIndex = 0;
    },
    onCurrencyChange(payload) {
      let newValue = payload.newValue, oldValue = payload.oldValue, newRate = 1, oldRate = 1, rate = 1;
      if (Array.isArray(newValue)) {
        newValue = newValue[0]
      } else return;
      if (Array.isArray(oldValue)) {
        oldValue = oldValue[0]
      } else return;
      if (newValue === oldValue) return;

      newRate = Number(this.currencyUnitOptions[newValue].rate);
      oldRate = Number(this.currencyUnitOptions[oldValue].rate);

      rate = oldRate / newRate;
      this.form.fabric_calculations = this.form.fabric_calculations.map(fabric => {
        fabric.price = (Number(fabric.price) * rate);
        return fabric;
      });
      this.form.expenses = this.form.expenses.map(expense => {
        expense.amount = (Number(expense.amount) * rate);
        return expense;
      });

      this.form.optionalExpenses = this.form.optionalExpenses.map(expense => {
        expense.amount = (Number(expense.amount) * rate);
        return expense;
      });
    },
    deleteExpensesOptions(index) {
      let expenseId = this.form.selectedExpenses[index];
      this.form.expenses = this.form.expenses.filter(item => +item.expense_id !== +expenseId)
      this.form.optionalExpenses = this.form.optionalExpenses.filter(item => +item.expense_id !== +expenseId)
      this.form.selectedExpenses.splice(index, 1);
    },
    changeTable(index) {
      this.currentSelectedTable = index;
      this.form = _.clone(this.inputForm[index]);
    },
    escSendEmailIsSubmit(payload) {
      // TODO: send mail functionality
      let content = [];
      let self = this;
      for (let i = 0; i < payload.emails.length; i++) {
        content.push(
            {
              email: payload.emails[i].index,
              language_id: payload.language,
            }
        )
      }
      let payloads = {
        url: 'api/esc-tables/' + this.form.id + '/share',
        id: this.id,
        contents: {
          emails: content
        }
      }

      this.$store.dispatch(CREATE_ITEM, payloads).then(result => {
        if (result.status) {
          self.sweetAlertSuccess(self.$t('general.success'));
        }
      });
    },
    openToSend() {
      this.$modal.show('open_to_send_form')
    },
    addNowRowTable() {
      this.form.fabric_calculations.push({
        fabric: null,
        width: null,
        weight: null,
        consumption: null,
        price: null,
        total: null,
        unit_id: null,
      })
    },
    deleteRowTable(index) {
      this.form.fabric_calculations.splice(index, 1);
    },
    imageChange() {
      this.form.image = this.$refs.file.files[0];
      let imageHolder = $('#image_holder');
      imageHolder.attr('src', URL.createObjectURL(this.form.image));
    },
    fixEscData(escTable, skipCurrent = false) {
      let self = this;
      let tmpItem = _.clone(this.defaultForm);
      tmpItem.id = escTable.id;
      tmpItem.copied_id = escTable.copied_id;
      tmpItem.date = moment(escTable.date, LARAVEL_DATE_FORMAT);
      tmpItem.description = escTable.description;
      tmpItem.esc_number = escTable.esc_number;
      tmpItem.esc_pdf_link = escTable.esc_pdf_link;
      tmpItem.esc_company_pdf_link = escTable.esc_company_pdf_link;
      tmpItem.esc_excel_link = escTable.esc_excel_link;
      tmpItem.company_id = escTable.company_id.toString();
      tmpItem.updated_at = escTable.updated_at;
      tmpItem.created_at = escTable.created_at;
      tmpItem.order_id = Number(escTable.order_id);
      tmpItem.model = escTable.model;
      tmpItem.wastage = escTable.wastage || this.escConfigurations.wastage;
      tmpItem.dividend = escTable.dividend || this.escConfigurations.dividend;
      tmpItem.exchange_difference = escTable.exchange_difference;
      tmpItem.expenses = [];
      tmpItem.selectedExpenses = [];
      tmpItem.optionalExpenses = this.cloneForce(self.optionalExpenses);
      tmpItem.image_link = escTable.image_link;
      tmpItem.image_link_large = escTable.image_link_large;
      tmpItem.currency_unit_code = escTable.currency_unit.code;
      tmpItem.preview_currency_unit_code = escTable.preview_currency_unit ? escTable.preview_currency_unit.code : 'TL';
      tmpItem.in_use = escTable.in_use;
      tmpItem.is_submitted = escTable.hasOwnProperty('is_submitted') && escTable.is_submitted === true;

      if ('fabric_calculations' in escTable && escTable.fabric_calculations !== []) {
        tmpItem.fabric_calculations = escTable.fabric_calculations;
      }
      if ('esc_table_expenses' in escTable && escTable.esc_table_expenses !== []) {
        for (let i = 0; i < escTable.esc_table_expenses.length; i++) {
          let current = escTable.esc_table_expenses[i];
          if (current.expense_id.toString() in self.convertArrayToObjectByKey(self.optionalExpenseOptions, 'expense_id')) {
            for (let j = 0; j < tmpItem.optionalExpenses.length; j++) {
              if (tmpItem.optionalExpenses[j].expense_id.toString() === current.expense_id.toString()) {
                tmpItem.optionalExpenses[j].amount = current.amount;
              }
            }
          } else {
            tmpItem.expenses.push(current);
          }
        }
      }

      let tempSelectedExpenses = [];
      for (let i = 0; i < tmpItem.expenses.length; i++) {
        tempSelectedExpenses.push(tmpItem.expenses[i].expense_id.toString());
      }
      tmpItem.selectedExpenses = tempSelectedExpenses;

      return tmpItem;
    },
    createNewTable() {
      if(this.inputForm[this.currentSelectedTable].user!=null&&this.inputForm[this.currentSelectedTable].user.id!=this.authUser.user_id){
        this.sweetAlertError(this.$t('general.the_person_who_prepared_it_can_only_update_it'));
        return false;
      }
      
      if (!this.isUserGranted('EscTable', ['create'])) {
        return false;
      }

      let self = this;
      let copy_id = this.form.id;
      /*      for (let i = 0; i < this.inputForm.length; i++) {
              if (Boolean(this.inputForm[i].in_use)) {
                copy_id = this.inputForm[i].id;
              }
            }*/
      this.$store.dispatch(GET_ITEMS, {
        url: `api/esc-tables/${copy_id}/copy`
      }).then((result) => {
        let formData = this.fixEscData(result.data);
        self.inputForm.push(formData);
        self.changeTable(this.inputForm.length - 1);
        self.sweetAlertSuccess('esc.created_new_table');
      })
    },
    duplicateTable() {
      if (!this.isUserGranted('EscTable', ['create'])) {
        return false;
      }

      let self = this;
      let duplicate_id = this.form.id;

      this.$store.dispatch(GET_ITEMS, {
        url: `api/esc-tables/${duplicate_id}/duplicate`
      }).then((result) => {
        let item = result.data;
        this.$router.push({ name: 'esc.entry.edit', params: { id: item.id, } });
        self.sweetAlertSuccess('esc.created_new_table');
      })
    },
    updateTable(){
    if(this.inputForm[this.currentSelectedTable].user!=null&&this.inputForm[this.currentSelectedTable].user.id!=this.authUser.user_id){
      this.sweetAlertError(this.$t('general.the_person_who_prepared_it_can_only_update_it'));
      return false;
    }
    if (!this.isUserGranted('EscTable', ['update'])) {
        return false;
      }

      if (this.isSubmitting) return;
      this.isSubmitting = true;
      this.sweetAlertLoading();
      let self = this;
      let form = new FormData();
      let submitForm = this.cloneForce(this.form);
      form.append('company_id', submitForm.company_id);
      if (submitForm.image) {
        form.append('image', this.form.image);
      }
      form.append('model', submitForm.model);
      form.append('description', submitForm.description);
      form.append('currency_unit_code', submitForm.currency_unit_code);
      form.append('preview_currency_unit_code', submitForm.preview_currency_unit_code);
      form.append('order_id', 0);
      form.append('amount', this.generalTotalAmount);
      form.append('exchange_rate', submitForm.exchange_rate);

      let tempExpenses = [...submitForm.expenses];

      for (let i = 0; i < submitForm.optionalExpenses.length; i++) {
        let current = {...submitForm.optionalExpenses[i]};
        current.amount = Number(Number(current.amount));
        tempExpenses.push(current)
      }

      submitForm.fabric_calculations = submitForm.fabric_calculations.map(fabric => {
        fabric.price = Number(Number(fabric.price));
        fabric.total = Number(fabric.price * Number(fabric.consumption));
        return fabric;
      })

      this.createFormDataForList(form, 'expenses', tempExpenses)
      this.createFormDataForList(form, 'fabric_calculations', submitForm.fabric_calculations)

      let payload = {
        url: 'api/esc-tables/' + submitForm.id,
        id: this.id,
        contents: form,
      }
      this.$store.dispatch(CREATE_ITEM, payload).then(response => {
        let self = this;
        if (response.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_updated')).then((res) => {
             this.isSubmitting = false;
          })
        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
             this.isSubmitting = false;
        }
      });
    },
    submitESC() {
      if (!this.isUserGranted('EscTable', ['create'])) {
        return false;
      }

      if (this.isSubmitting) return;
      this.isSubmitting = true;
      this.sweetAlertLoading();
      let self = this;
      let form = new FormData();
      let submitForm = this.cloneForce(this.form);
      form.append('company_id', submitForm.company_id);
      if (submitForm.image) {
        form.append('image', this.form.image);
      }
      form.append('model', submitForm.model);
      form.append('description', submitForm.description);
      form.append('currency_unit_code', submitForm.currency_unit_code);
      form.append('preview_currency_unit_code', submitForm.preview_currency_unit_code);
      form.append('order_id', 0);
      form.append('amount', this.generalTotalAmount);
      form.append('exchange_rate', submitForm.exchange_rate);

      let tempExpenses = [...submitForm.expenses];

      for (let i = 0; i < submitForm.optionalExpenses.length; i++) {
        let current = {...submitForm.optionalExpenses[i]};
        current.amount = Number(Number(current.amount));
        tempExpenses.push(current)
      }

      submitForm.fabric_calculations = submitForm.fabric_calculations.map(fabric => {
        fabric.price = Number(Number(fabric.price));
        fabric.total = Number(fabric.price * Number(fabric.consumption));
        return fabric;
      })

      this.createFormDataForList(form, 'expenses', tempExpenses)
      this.createFormDataForList(form, 'fabric_calculations', submitForm.fabric_calculations)

      let payload = {
        url: submitForm.id === null ? 'api/esc-tables' : 'api/esc-tables/' + submitForm.id,
        id: this.id,
        contents: form,
      }
      this.$store.dispatch(CREATE_ITEM, payload).then(result => {
        self.sweetAlertClose();
        if (result.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_created'));
          self.form.id = result.data.id;
          self.form.esc_number = result.data.esc_number;
          self.form.is_submitted = true;
          self.form.esc_pdf_link = result.data.esc_pdf_link;
          self.form.esc_company_pdf_link = result.data.esc_company_pdf_link;
          self.form.esc_excel_link = result.data.esc_excel_link;
          self.isSubmitting = false;

          if (self.inputForm.length) {
            self.inputForm[this.currentSelectedTable].id = result.data.id;
            self.inputForm[this.currentSelectedTable].esc_number = result.data.esc_number;
            self.inputForm[this.currentSelectedTable].is_submitted = true;
            self.inputForm[this.currentSelectedTable].esc_pdf_link = result.data.esc_pdf_link;
            self.inputForm[this.currentSelectedTable].esc_company_pdf_link = result.data.esc_company_pdf_link;
            self.inputForm[this.currentSelectedTable].esc_excel_link = result.data.esc_excel_link;
          } else {
            let data = result.data;
            data.is_submitted = true;
            self.inputForm.push(this.cloneForce(this.fixEscData(data)));
            this.changeTable(0)
          }

          if ('redirectName' in self.$route.query) {
            self.$router.replace({name: 'order.create', params: self.$route.query, query: {esc_id: result.data.id}})
          }
        } else {
          self.isSubmitting = false;
          self.form.is_submitted = false;
          let response = result.data.response;
          if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message')) {
            self.sweetAlertError(response.data.message);
          } else {
            self.sweetAlertError(response.data);
          }
        }
      });
    },
  },
  watch: {
    'form.selectedExpenses': {
      handler: function (newValue, oldValue) {
        if (newValue === undefined || oldValue === undefined) return;
        for (let i = 0; i < oldValue.length; i++) {
          if (!newValue.includes(oldValue[i])) {
            for (let j = 0; j < this.form.expenses.length; j++) {
              if (Number(this.form.expenses[j].expense_id) === Number(this.expensesOptions[oldValue[i]].id)) {
                this.form.expenses.splice(j, 1);
                break;
              }
            }
          }
        }
        for (let i = 0; i < newValue.length; i++) {
          if (!oldValue.includes(newValue[i]) && !(newValue[i].toString() in this.convertArrayToObjectByKey(this.form.expenses, 'expense_id'))) {
            this.form.expenses.push({
              expense_id: Number(newValue[i]),
              amount: null,
            });
          }
        }

        newValue.map(item => {
          item.amount = item.amount ? item.amount.replace(",",".") : null
          return item;
        })
      }
    },
    'form.currency_unit_code': {
      handler: function (newValue, oldValue) {
        if (newValue === undefined || oldValue === undefined) return;
        this.form.exchange_rate = this.currencyUnitOptions[newValue].rate;
      }
    },
    'form.fabric_calculations': {
      handler: function (newValue, oldValue) {

        if (newValue === undefined || oldValue === undefined) return;

        newValue.map(item => {
          item.price = item.price ? item.price.replace(",",".") : item.price,
          item.consumption =  item.consumption ? item.consumption.replace(",",".") : item.consumption
          return item;
        })
      },
      deep: true
    },
    'form.optionalExpenses': {
      handler: function (newValue, oldValue) {
        if (newValue === undefined || oldValue === undefined) return;
        newValue.map(item => {
          item.amount = item.amount ? item.amount.replace(",",".") : item.amount
          return item;
        })
      },
      deep: true
    },
    'form.expenses': {
      handler: function (newValue, oldValue) {
        if (newValue === undefined || oldValue === undefined) return;
        newValue.map(item => {
          item.amount = item.amount ? item.amount.replace(",",".") : item.amount
          return item;
        })
      },
      deep: true
    }
  },
  mounted() {
    let self = this;

    if (!(this.isUserGranted('EscTable', ['view', 'create', 'update' ]))) {
      return false;
    }

    let promises = [];
    this.sweetAlertLoading();
    promises.push(this.$store.dispatch(REST_GET_ITEM, {
      url: 'api/expenses',
      acceptPromise: true,
      filters: {}
    }));
    promises.push(this.$store.dispatch(REST_GET_ITEM, {
      url: 'api/companies',
      acceptPromise: true,
      filters: {}
    }));
    promises.push(this.$store.dispatch(GET_ITEMS, {
      url: 'api/currency-rate'
    }));

    promises.push(this.$store.dispatch(GET_ITEMS, {
      url: 'api/units'
    }));
    if (self.id != null) {
      promises.push(self.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
        url: 'api/esc-tables/' + this.id,
        acceptPromise: true
      }));
    }
    Promise.all(promises).then(results => {
      if (results[0].status) {
        let temp = results[0].data;
        let tempExpenses = [], tempOptionalExpenses = [], tempFormOptionExpenses = [];
        for (let i = 0; i < temp.length; i++) {
          {
            if (Number(temp[i].is_default) === 1) {
              tempOptionalExpenses.push(temp[i])
              tempFormOptionExpenses.push({
                expense_id: temp[i].id,
                amount: null,
                name: temp[i].translations[0].name
              })
            } else {
              tempExpenses.push(temp[i]);
            }
          }
        }
        self.expensesOptions = self.convertArrayToObjectByKey(tempExpenses, 'id');
        self.optionalExpenseOptions = [...tempFormOptionExpenses];
        self.optionalExpenses = [...tempFormOptionExpenses];
      }
      if (results[1].status) {
        let temp = results[1].data;
        let returnValues = {};
        for (let i = 0; i < temp.length; i++) {
          {
            returnValues[temp[i].id] = temp[i].name;
          }
        }
        self.companyOptions = returnValues;
      }
      if (results[2].status) {
        self.currencyUnitOptions = results[2].data.data;
      }

      if (results[3].status) {
        let data = results[3].data;
        data = data.filter(item => item.code === "M" || item.code === "KG");
        this.unitOptions = self.convertArrayToObjectByKey(data, 'id', 'translations.0.name');
      }

      if (self.id != null) {
        if (results[4].status) {
          self.inputForm = [];
          let inUseId = 0;
          for (let i = 0; i < results[4].data.length; i++) {
            let escTable = results[4].data[i];
            self.currentSelectedTable = i;
            let tmpItem = this.cloneForce(this.defaultForm);
            tmpItem.id = escTable.id;
            tmpItem.user=escTable.user;
            tmpItem.date = moment(escTable.date, LARAVEL_DATE_FORMAT);
            tmpItem.description = escTable.description;
            tmpItem.esc_number = escTable.esc_number;
            tmpItem.esc_pdf_link = escTable.esc_pdf_link;
            tmpItem.esc_company_pdf_link = escTable.esc_company_pdf_link;
            tmpItem.esc_excel_link = escTable.esc_excel_link;
            tmpItem.updated_at = escTable.updated_at;
            tmpItem.created_at = escTable.created_at;
            tmpItem.company_id = escTable.company_id.toString();
            tmpItem.order_id = Number(escTable.order_id);
            tmpItem.model = escTable.model;
            tmpItem.order_number = escTable.order ? escTable.order.order_number : '-';
            tmpItem.wastage = Number(escTable.wastage) || this.escConfigurations.wastage;
            tmpItem.dividend = Number(escTable.dividend) || this.escConfigurations.dividend;
            tmpItem.exchange_difference = Number(escTable.exchange_difference) || this.escConfigurations.exchange_difference;
            tmpItem.image_link = escTable.image_link;
            tmpItem.image_link_large = escTable.image_link_large;
            tmpItem.currency_unit_code = escTable.currency_unit ? escTable.currency_unit.code : 'TL';
            tmpItem.preview_currency_unit_code = escTable.preview_currency_unit ? escTable.preview_currency_unit.code : 'TL';
            tmpItem.in_use = escTable.in_use;
            tmpItem.is_submitted = true;
            tmpItem.expenses = [];
            tmpItem.optionalExpenses = JSON.parse(JSON.stringify(self.optionalExpenses));
            if (Number(escTable.in_use)) {
              inUseId = i;
            }
            if ('fabric_calculations' in escTable && escTable.fabric_calculations !== []) {
              tmpItem.fabric_calculations = escTable.fabric_calculations;
            }
            if ('duplicated' in escTable && escTable.duplicated !== []) 
            {
              tmpItem.duplicated=escTable.duplicated;
            }
            if ('esc_table_expenses' in escTable && escTable.esc_table_expenses !== []) {
              for (let i = 0; i < escTable.esc_table_expenses.length; i++) {
                let current = escTable.esc_table_expenses[i];
                if (current.expense_id.toString() in self.convertArrayToObjectByKey(self.optionalExpenseOptions, 'expense_id')) {
                  for (let j = 0; j < tmpItem.optionalExpenses.length; j++) {
                    if (tmpItem.optionalExpenses[j].expense_id.toString() === current.expense_id.toString()) {
                      tmpItem.optionalExpenses[j].amount = current.amount;
                    }
                  }
                } else {
                  tmpItem.expenses.push(current);
                }
              }
            }

            let tempSelectedExpenses = [];
            for (let i = 0; i < tmpItem.expenses.length; i++) {
              tempSelectedExpenses.push(tmpItem.expenses[i].expense_id.toString());
            }
            tmpItem.selectedExpenses = tempSelectedExpenses;


            let finalForm = this.cloneForce(tmpItem);
            self.inputForm.push(finalForm);
          }

          self.currentSelectedTable = inUseId;
          this.changeTable(inUseId);
        }
      } else {
        self.form.optionalExpenses = this.cloneForce(self.optionalExpenses);
      }

      self.sweetAlertClose();
    })

    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("esc.esc_table"), route: "esc.entry.new"},
      {title: this.$t("esc.new_esc")}
    ]);
  },
}

</script>

<style scoped>
.firstTable1 {
  border-top-left-radius: 9px;
}

table, td, th {
  border: 1px solid #ddd;
  text-align: left;
}

#mainDiv {
  border-radius: 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 4px;
}

th, td {
  padding: 15px;
}

#filter-card {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
}

*:focus {
  outline: none;
}
</style>
